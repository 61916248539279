import React from "react"
import {
  ActiveImage,
  ThumbnailDot,
  ThumbnailImage,
  ThumbnailWrapper,
  CarouselSlide,
  CarouselHolder,
  Back,
  Next,
} from "./styles"
import { CarouselProvider, Slider } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

export const ImageCarousel = ({ images }) => {
  return (
    <CarouselProvider
      naturalSlideWidth={500}
      naturalSlideHeight={500}
      totalSlides={images.length}
    >
      <CarouselHolder>
        <Slider>
          {images.map((node, index) => (
            <CarouselSlide index={index} key={`carousel-${node.id}`}>
              <ActiveImage image={node.localFile?.childImageSharp?.gatsbyImageData} />
            </CarouselSlide>
          ))}
        </Slider>
        <Back />
        <Next />
      </CarouselHolder>
      <ThumbnailWrapper>
        {images.map((node, index) => (
          <ThumbnailDot slide={index} key={`carousel-thumb-${node.id}`}>
            <ThumbnailImage image={node.localFile?.childImageSharp?.gatsbyImageData} />
          </ThumbnailDot>
        ))}
      </ThumbnailWrapper>
    </CarouselProvider>
  );
}
