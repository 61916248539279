import React from "react"
import {
  PageDivider,
  Section,
  ProductTitle,
  // ProductDescription,
  CTA,
  ProductPrice,
  BGSection,
} from "./styles"
import { pushGTMEvent, GTMConvertProduct } from "../../components/globals"
import { ImageCarousel } from "../../components/ImageCarousel"

export const ProductPageContent = ({ data, context }) => {
  const { images } = context

  React.useEffect(() => {
    pushGTMEvent("view_item", [GTMConvertProduct(context)])
  }, [context])

  return (
    <PageDivider>
      <Section>
        <ImageCarousel images={images ?? []} />
      </Section>
      <BGSection>
        <ProductTitle>{context?.title}</ProductTitle>
        <ProductPrice>{context?.price},- NOK</ProductPrice>
        {/* <ProductDescription>{context?.description}</ProductDescription>*/}
        <CTA
          to="/linser/"
          state={context}
          onClick={() => {
            pushGTMEvent("add_to_cart", [GTMConvertProduct(context)])
          }}
        >
          Bestill
        </CTA>
      </BGSection>
    </PageDivider>
  )
}
