import React from "react"
import { InsideWrapper } from "../components/globals"
import Layout from "../components/layout"
import { ProductPageContent } from "../pageContent/productpageContent"

const ProductPage = ({ data, pageContext }) => {
  const title = `Bestill ${pageContext.title} hos Lambertseter Ur & Optikk`
  const description = `Hos Lambertseter Ur & Optikk får du bestilt ${pageContext.title} for ${pageContext.price},-`
  return (
    <Layout
      title={title}
      description={description}
      keywords={pageContext.title.split(" ")}
    >
      <InsideWrapper>
        <ProductPageContent data={data} context={pageContext} />
      </InsideWrapper>
    </Layout>
  )
}

export default ProductPage
