import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { Dot, Slide, ButtonBack, ButtonNext } from "pure-react-carousel"

export const Back = styled(ButtonBack)`
  position: absolute;
  display: block;
  left: 5px;
  top: 0;
  bottom: 0;
  z-index: 2;
  height: 30px;
  width: 30px;
  background: #ffffff;
  border-radius: 50%;
  border: 0;
  margin: auto 0;
  padding: 0;
  box-shadow: 0 1.1px 14.4px -6px rgba(0, 0, 0, 0.053),
    0 2.5px 22.8px -6px rgba(0, 0, 0, 0.077),
    0 4.8px 26.4px -6px rgba(0, 0, 0, 0.095),
    0 8.5px 26.7px -6px rgba(0, 0, 0, 0.113),
    0 15.9px 26px -6px rgba(0, 0, 0, 0.137),
    0 38px 35px -6px rgba(0, 0, 0, 0.19);

  &:focus {
    outline: 0;
  }
  &:after {
    content: "‹";
    font-size: 30px;
    height: 100%;
    width: 100%;
  }
`
export const Next = styled(ButtonNext)`
  position: absolute;
  display: block;
  right: 5px;
  top: 0;
  bottom: 0;
  z-index: 2;
  height: 30px;
  width: 30px;
  background: #ffffff;
  border-radius: 50%;
  border: 0;
  margin: auto 0;
  padding: 0;
  box-shadow: 0 1.1px 14.4px -6px rgba(0, 0, 0, 0.053),
    0 2.5px 22.8px -6px rgba(0, 0, 0, 0.077),
    0 4.8px 26.4px -6px rgba(0, 0, 0, 0.095),
    0 8.5px 26.7px -6px rgba(0, 0, 0, 0.113),
    0 15.9px 26px -6px rgba(0, 0, 0, 0.137),
    0 38px 35px -6px rgba(0, 0, 0, 0.19);

  &:focus {
    outline: 0;
  }
  &:after {
    content: "›";
    font-size: 30px;
    height: 100%;
    width: 100%;
  }
`
export const CarouselSlide = styled(Slide)`
  & > div {
    display: flex;
    justify-content: center;
    align-content: center;
  }
`
export const CarouselHolder = styled.div`
  position: relative;
`
export const ActiveImage = styled(GatsbyImage)`
  flex: 1 0 auto;
  width:100%;
`
export const ThumbnailDot = styled(Dot)`
  background: transparent;
  border: none;
`
export const ThumbnailImage = styled(GatsbyImage)``
export const ThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin: 1em 0;
`
