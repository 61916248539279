import styled from "styled-components"
import { Link } from "gatsby"

export const PageDivider = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: row;
  overflow: hidden;
  padding: 10vh 0;
  @media screen and (max-width: 991px) {
    flex-flow: row wrap;
    & > div  {
      flex-basis: 100%;
    }
  }
`
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  padding: 1em;
`
export const BGSection = styled(Section)`
  background: #0b0c14;
  border-radius: 10px;
  color: white;
  padding: 2em;
  height:fit-content;
  margin:auto;
`
export const ProductTitle = styled.h1`
  font-size: 2em;
  margin-bottom: 0.25em;
`
export const ProductDescription = styled.p`
  opacity: 0.76;
  color: white;
  font-size: 1em;
  line-height: 150%;
  letter-spacing: 1px;
  margin: 1em auto;
  white-space: pre-wrap;
`
export const ProductPrice = styled.h4`
  font-size: 1.5em;
  margin-bottom: 0.5em;
  color: #8ab1e6;
`
export const CTA = styled(Link)`
  text-decoration: none;
  padding: 0.5em 1em;
  border: 0;
  cursor: pointer;
  text-align: center;
  border-radius: 5px;
  font-weight: 500;
  letter-spacing: 1.2px;
  background: #405de6;
  color: #ffffff;
  margin-top: 2em;
  margin-bottom: 2em;
`
